import { HStack, VStack } from "@chakra-ui/react"
import Button from "@pathwright/ui/src/components/button/Button"
import Fullscreen, {
  FULLSCREEN_LAYER_OVERLAY
} from "@pathwright/ui/src/components/fullscreen/Fullscreen"
import { useObserveSizeContext } from "@pathwright/ui/src/components/observers/ObserveSizeContext"
import {
  DANGEROUS_RED,
  SECONDARY_GRAY,
  SUCCESS_GREEN
} from "@pathwright/ui/src/components/utils/colors"
import PropTypes from "prop-types"
import styled from "styled-components"
import Portal from "../portal/Portal"
import Alert from "./Alert"

const StyledAlert = styled(Alert)`
  width: 100%;
  max-width: initial;
  min-height: 50px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px;
  grid-template-columns: min-content min-content;
  justify-content: center;
  border: none;
  position: relative;
  border-radius: 0;

  &.Alert--info {
    background-color: whitesmoke;
    box-shadow: inset 0 -10px 14px 0px rgb(0 0 0 / 20%);

    .IconButton .Pathicon {
      height: 1.5em !important;
      width: 1.5em !important;
      line-height: 1.5em;
      color: ${SECONDARY_GRAY} !important;
    }

    .MessageButton {
      font-weight: 700 !important;
      font-size: 1.2em;
    }
  }

  > i {
    color: ${(props) => props.color};
  }

  .Button {
    font-size: 1.2em;
    cursor: pointer;
  }

  .MessageButton {
    font-weight: 700;
    padding: 0;
    margin: 0;
    text-decoration: ${(props) => (props.hasAction ? "none" : "")};
  }

  .ActionButton {
    border: none;
    font-size: 1rem;

    &.fullwidth-button {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }

    .Pathicon {
      font-size: 14px;
      margin-top: -4px;
      margin-left: 4px;
    }
  }
`

const AlertBanner = ({
  message,
  subMessage,
  alertType,
  icon,
  label,
  actionIcon,
  children,
  ...actionProps
}) => {
  const typeProp = { [alertType]: true }
  const color =
    alertType === "error"
      ? DANGEROUS_RED
      : alertType === "success"
      ? SUCCESS_GREEN
      : SECONDARY_GRAY

  const { to, href, onClick, onSubmit } = actionProps
  const hasAction = !!(to || href || onClick || onSubmit)
  const showActionButton = !!label

  return (
    <Portal portalElementID="alert">
      <StyledAlert
        {...typeProp}
        icon={icon}
        color={color}
        hasAction={showActionButton}
        style={{ display: "flex" }}
      >
        <HStack
          w={showActionButton ? "100%" : "unset"}
          justifyContent="space-between"
        >
          <VStack spacing={0} alignItems="flex-start">
            <strong>{message}</strong>
            {!!subMessage && <span>{subMessage}</span>}
          </VStack>
          {children ? (
            children
          ) : hasAction ? (
            <Button
              className={`ActionButton${
                !showActionButton ? " fullwidth-button" : ""
              }`}
              styleType="blank"
              label={label}
              postIcon={actionIcon || actionProps.postIcon}
              {...actionProps}
            />
          ) : null}
        </HStack>
      </StyledAlert>
    </Portal>
  )
}

AlertBanner.defaultProps = {
  alertType: "error",
  icon: "",
  actionIcon: ""
}

AlertBanner.propTypes = {
  message: PropTypes.string.isRequired,
  subMessage: PropTypes.string,
  alertType: PropTypes.oneOf(["error", "info", "success"]),
  icon: PropTypes.string, // Main icon (far left)
  actionIcon: PropTypes.string, // Icon for action button (far right)
  ...Button.propTypes
}

AlertBanner.displayName = "AlertBanner"

const AlertBannerContainer = () => {
  const { useSizeRef } = useObserveSizeContext()
  const setSizeRef = useSizeRef("alert")

  return (
    <Fullscreen
      className="FullscreenAlertBanner"
      allowScroll={false}
      background={null}
      layer={FULLSCREEN_LAYER_OVERLAY}
    >
      <div id="alert" ref={setSizeRef} />
    </Fullscreen>
  )
}

export default {
  Banner: AlertBanner,
  Container: AlertBannerContainer
}
