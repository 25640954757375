import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import get from "lodash/get"
import { useResourceQuery } from "../../api/generated"
import FeatureBlockUI from "../../feature/block/FeatureBlockUI"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { tagAttachmentContextPropType } from "../../tag/propTypes"
import { getTagsContext } from "../../tag/utils"

const DiscussionTagFeatureBlock = ({ context, to }) => {
  const { t } = useTranslate()
  const { school } = usePathwrightContext()
  const tagsContext = getTagsContext(context)

  // Query resource based on context.
  const resourceId = get(tagsContext, "resource_id")
  const resourceQuery = useResourceQuery({
    variables: { id: resourceId },
    skip: !resourceId
  })
  const resourceName = get(resourceQuery, "data.resource.name")

  const sharedFeatureInfo = {
    icon: "tag",
    title: t("Topics"),
    tooltip: t("Prompt and organize discussion around topics."),
    link: "https://help.pathwright.com/en/articles/6026245-discussion-topics"
  }

  if (tagsContext) {
    return (
      <FeatureBlockUI
        featureInfo={{
          desc: t("Organize discussion topics in {{ resource }}.", {
            resource: resourceName
          }),
          ...sharedFeatureInfo
        }}
        to={to}
      />
    )
  }

  return (
    <FeatureBlockUI
      featureInfo={{
        desc: t("Organize discussion topics across Paths.", {
          school: school.name
        }),
        ...sharedFeatureInfo
      }}
      to={to}
    />
  )
}

DiscussionTagFeatureBlock.displayName = "DiscussionTagFeatureBlock"

DiscussionTagFeatureBlock.propTypes = {
  context: tagAttachmentContextPropType
}

export default DiscussionTagFeatureBlock
