import AlertBanner from "@pathwright/ui/src/components/alert/AlertBanner"
import { getEnv } from "@pathwright/ui/src/components/utils/env"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"

export const useShouldShowStripeReconnect = () => {
  if (
    usePathwrightContext().school.show_stripe_reconnect &&
    getEnv("production")
  ) {
    // possibly should use some check other than getEnv("production")
    return true
  }
  return false
}

const StripeReconnectBanner = () => {
  if (!useShouldShowStripeReconnect()) return null

  return (
    <AlertBanner.Banner
      message="Upgrade your Stripe Connection"
      to="/manage/school/features/commerce/stripe/"
    />
  )
}

StripeReconnectBanner.displayName = "StripeReconnectBanner"

const StripeConfirmAlert = ({ subscriptionID }) => (
  <AlertBanner.Banner
    message="Confirm your payment information"
    to={`/payment/confirm-stripe/${subscriptionID}/`}
  />
)

StripeConfirmAlert.displayName = "StripeConfirmAlert"

export default {
  Reconnect: StripeReconnectBanner,
  Confirm: StripeConfirmAlert
}
