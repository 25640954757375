import { ButtonProps } from "@chakra-ui/react"
import { Media, MediaInput, MediaTag, MediaType } from "@pathwright/media-utils"
import { PickerOptions } from "filestack-js"
import { ComponentType, ReactElement } from "react"
import { SyncerProps } from "./index"

export type MediaManagerContainerProps = {
  // ** Essential props **
  // Permissions key for mutations
  contextKey: string
  // Path to store media in Filestack
  mediaStoragePath: string
  // Limits what can be seen and uploaded
  mediaType: MediaType
  // Saved with each media item to identify its creator
  userID: number | string

  // ** Most use cases need the following props **
  // Returns selected media from MM; optional when in "view only" mode
  onChooseMedia?: (media: Media[]) => void
  // Saved with each media item to identify its account creator
  accountID?: number | string
  // Render custom MM launcher button
  renderButton?: (props: ButtonProps) => ReactElement

  // ** Situational props **
  // If true, disables plus buttons (ex: school-wide Media Manager)
  isSuperuser?: boolean
  preventChoosingMedia?: boolean
  // Override Filestack picker options
  pickOptions?: PickerOptions
  buttonText?: string
  className?: string
  // In certain cases where we want the MM not to show anything
  forceEmpty?: boolean
  customEmptyMessage?: string

  // Allow video download buttons to appear, such as on the sidebar preview
  allowDownloadableVideos?: boolean

  Syncer?: ComponentType<SyncerProps>
}

export namespace MediaProviderType {
  export type Props = MediaManagerContainerProps & {
    children: ReactElement
    onClose: () => void
  }

  export type State = {
    renderedMedia: Media[]
    renderedTags: MediaTag[]
    isUploading: boolean
    uploadingVideo: boolean
    uploadCount: number
    isEditing: boolean
    isLoading: boolean
    currentSearch: string
    currentFilter: MediaFilter.Type
    currentTypeFilter: MediaType | null
    selectedMediaItem: Media | null
    selectedBatch: Media[]
    currentPageCount: number
    currentPage: number
    error?: Error | string | null
  }

  export type Handlers = {
    handleReset: (resetState?: State) => void
    handleClearSelectedBatch: () => void
    handleDeselectMedia: () => void
    handleStartUploading: (options?: { isVideo?: boolean }) => void
    handleStopUploading: () => void
    handleSetUploadCount: (count: number) => void
    handlePaginateMedia: (nextPage: number) => void
    handleStartEditing: (selectedMediaItem: Media) => void
    handleStopEditing: () => void
    handleSetSelectedBatch: (selectedBatch: Media[]) => void
    handleSetFilter: (filter: MediaFilter.Type) => void
    handleSetTypeFilter: (filter?: MediaType) => void
    handleSetSearch: (search: string) => void
    handleStartLoading: () => void
    handleStopLoading: () => void
    handleSaveMediaBatch: (media: MediaInput[]) => void
    handleDeleteBatch: () => void
    handleDeleteMedia: (media: Media) => void
    handleStartSavingMedia: (media: MediaInput) => void
  }

  export type Context = Omit<Props, "children"> &
    State &
    Handlers & {
      // Apollo
      mediaLoading?: boolean
      accountTags?: MediaTag[]
      tagsLoading?: boolean
      gqlError?: any
    }
}

export type Option = {
  label: string
  value: string
}

export namespace MediaFilter {
  export enum Type {
    ALL = "all",
    TAGGED = "tagged",
    RECENT = "recent",
    UPLOADS = "uploads"
  }

  export type Options = Record<MediaFilter.Type, Option>
}

export enum MediaTypeFilter {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  FILE = "FILE",
  AUDIO = "AUDIO",
  DOCUMENT = "DOCUMENT",
  ARCHIVE = "ARCHIVE",
  ANY = "ANY",
  LINK = "LINK"
}

export type MediaTypeFilterOptions = Record<MediaTypeFilter, Option>
