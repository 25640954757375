import { getUrlFromRoute } from "@pathwright/ui/src/components/link/utils"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import { getCardPath, getRoute } from "@pathwright/web/src/modules/utils/urls"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Redirect } from "react-router-dom"
import { historyEntries } from "./router"
import { getLegacyRouterState, historyListener } from "./utils"

export const AppRouteContext = React.createContext()

export const AppRouteContextProvider = ({ children }) => {
  const navStore = window.App.getStore("navigation")
  // Used for triggering rerender when updating ref.
  const [tick, setTick] = useState(0)

  // Ref used for storing component references.
  const routeContextRef = useRef({
    PrimaryComponent: null,
    SecondaryComponent: null,
    ModalComponent: null,
    routeState: null
  })

  // NOTE: the following two refs could maybe be merged into one as they
  // they function quite similarly.
  const prevRouteContextRef = useRef(null)
  // Track the prev route context that was launched from a modal.
  // We want to ensure that if the user returns to the route matching this
  // route context that we also reset the primary component to that prev
  // route context's primary component.
  const prevModalRouteContextRef = useRef(null)

  // Handle navigation store showing_modal setting.
  const getModalLaunchedFrom = (routeState) => {
    let modalLaunchedFrom = null
    const currentModalLaunchedFrom = navStore.get("modal_launched_from_url")

    // Check if route has sub components.
    if (
      routeState.components ||
      (routeState.location.state && routeState.location.state.modal)
    ) {
      // Only proceed if a modal_launched_from_url does not already exist.
      if (currentModalLaunchedFrom) {
        modalLaunchedFrom = currentModalLaunchedFrom
      } else {
        // Get the default, either from the route or from the navStore.
        const defaultModalLaunchedFrom = routeState.getDefaultLaunchedFrom
          ? routeState.getDefaultLaunchedFrom(routeState)
          : navStore.request.getHomeLocation()

        // Consider the previous location.
        let previousLocation =
          historyEntries[historyEntries.length - 2]?.location

        // If the previous location begins with the default modal launched from,
        // we want to set the previous location to null.
        // NOTE: This is slightly a hack and my indicate an issue further up the chain.
        if (
          previousLocation &&
          defaultModalLaunchedFrom &&
          previousLocation.pathname.startsWith(defaultModalLaunchedFrom)
        ) {
          previousLocation = null
        }

        // Allow using the location's pathname when on a ?AUGMENTATION_PANEL_QUERY_PARAM path route.
        const cardPathLaunchedFrom = getCardPath(routeState.location)
          ? routeState.location.pathname
          : null

        // Get new modal_launched_from_url in order of priority.
        modalLaunchedFrom =
          currentModalLaunchedFrom ||
          previousLocation ||
          cardPathLaunchedFrom ||
          defaultModalLaunchedFrom
      }
      // Allow preserving the current modal launched from when it maches the current location pathname.
      // This is necessary when we're on a ?AUGMENTATION_PANEL_QUERY_PARAM route and navigate to another ?AUGMENTATION_PANEL_QUERY_PARAM route on the same
      // root pathname. There will be one route match for the root pathname and another for the ?AUGMENTATION_PANEL_QUERY_PARAM
      // route and we don't want to clear out the modal launched from when running this method for the
      // root pathname route.
    } else if (currentModalLaunchedFrom === routeState.location.pathname) {
      return currentModalLaunchedFrom
    }

    return modalLaunchedFrom
  }

  // Simple handler for updating Ref data + triggering
  // rerender with tick change.
  const handleUpdateAppRouteContext = (routeContext) => {
    // Set default modalLaunchedFrom
    routeContext.routeState.modalLaunchedFrom = getModalLaunchedFrom(
      routeContext.routeState
    )

    // Helper for determining if the next location matches the provided one.
    const getRefMatchesCurrentLocation = (ref) => {
      if (!ref.current || !ref.current.routeState) return false

      return (
        routeContext.routeState.location.pathname ===
          ref.current.routeState.location.pathname &&
        routeContext.routeState.location.search ===
          ref.current.routeState.location.search
      )
    }

    // If next location does not match the current, we're going somewhere.
    const hasLocationChanged = !getRefMatchesCurrentLocation(routeContextRef)

    // If next location matches the previous, we're going back.
    const hasLocationReturned =
      getRefMatchesCurrentLocation(prevRouteContextRef)

    // If next location matches the previous route context conttaining a modal, we're going back
    // to a previous modal route and we want to reset to that context's primary component.
    const hasLocationReturnedToPrevModal = getRefMatchesCurrentLocation(
      prevModalRouteContextRef
    )

    // Only update listener's about the location change if location has changed.
    if (hasLocationChanged) {
      historyListener(routeContext.routeState.location)
    }

    // Resets the current route context to a previous one.
    // This could either be the immediatlely prevous route context or
    // the last one containing the matching secondary location.
    function resetToPreviousRouteContext(prevRouteContextRef) {
      if (prevRouteContextRef.current.routeState.components) {
        // Swap out with previous state.
        routeContext.PrimaryComponent =
          prevRouteContextRef.current.PrimaryComponent

        routeContext.routeState.modalLaunchedFrom =
          prevRouteContextRef.current.routeState.modalLaunchedFrom
      }
    }

    // Determine if we need to return to a previous PrimaryComponent + modalLaunchedFrom
    // combination.
    if (hasLocationReturned) {
      resetToPreviousRouteContext(prevRouteContextRef)
    } else if (hasLocationReturnedToPrevModal) {
      resetToPreviousRouteContext(prevModalRouteContextRef)
    }

    // Update navStore modal_launched_from_url
    if (
      !routeContextRef.current.routeState ||
      getUrlFromRoute(routeContext.routeState.modalLaunchedFrom) !==
        getUrlFromRoute(routeContextRef.current.routeState.modalLaunchedFrom)
    ) {
      if (routeContext.routeState.modalLaunchedFrom) {
        // It appears that we currently expect the value to be a string,
        // hence converting via getUrlFromRoute.
        const modalLaunchedFrom = getUrlFromRoute(
          routeContext.routeState.modalLaunchedFrom
        )

        // Avoid considering a secondary component as a modal, allowing a modal
        // to take it's place (like if redirecting to a auth modal).
        if (routeContext.SecondaryComponent) {
          navStore.action.setIsShowingModal(false, modalLaunchedFrom)
        } else {
          navStore.action.setIsShowingModal(true, modalLaunchedFrom)
        }
      } else {
        // No sub components are used, so clear modal_launched_from_url.
        navStore.action.setIsShowingModal(false, null)
      }
    }

    if (hasLocationChanged) {
      // When primary component has changed, clear out prev modal route context when prev route context didn't contain a modal.
      // Hopefully this will prevent any unforeseen, unwanted UX returning the user to the wrong primary component.
      if (
        prevRouteContextRef.current?.PrimaryComponent !==
          routeContextRef.current.PrimaryComponent &&
        !prevRouteContextRef.current?.routeState?.modalLaunchedFrom
      ) {
        prevModalRouteContextRef.current = null
      }

      // Track previous modal route context.
      if (routeContextRef.current.routeState?.modalLaunchedFrom) {
        prevModalRouteContextRef.current = { ...routeContextRef.current }
      }

      // Track previous route context.
      prevRouteContextRef.current = { ...routeContextRef.current }
    }

    routeContextRef.current = {
      ...routeContextRef.current,
      ...routeContext
    }
    setTick(tick + 1)
  }

  return (
    <AppRouteContext.Provider
      value={{
        // Passing routeContextRef as an escape hatch in order to access the
        // current value, not that value which is in state, which could be stale
        // if multiple routes match the current path, which is the case for
        // newer ?AUGMENTATION_PANEL_QUERY_PARAM routes.
        // Without this escape hatch, the ?AUGMENTATION_PANEL_QUERY_PARAM route would reset the PrimaryComponent
        // to it's default primary component beause the routeContext PrimaryComponent
        // would be null. Though, this likley only happens on initial page load when
        // no PrimaryComponent has been set in state yet.
        routeContextRef: routeContextRef,
        routeContext: routeContextRef.current,
        updateAppRouteContext: handleUpdateAppRouteContext
      }}
    >
      {children}
    </AppRouteContext.Provider>
  )
}

AppRouteContextProvider.displayName = "AppRouteContextProvider"

const AppRoute = ({ route, ...routeState }) => {
  const pwContext = usePathwrightContext()
  const [redirect, setRedirect] = useState(null)
  // Assuming we're awaiting a callback from route.onEnter if the function expects a third arg.
  // Note: function length property is unreliable: https://stackoverflow.com/a/4138036/6362803
  const [awaitingCallback, setAwaitingCallback] = useState(
    !!route.onEnter && route.onEnter.length === 3
  )
  const { routeContextRef, routeContext, updateAppRouteContext } =
    useContext(AppRouteContext)

  const legacyRouterState = getLegacyRouterState(routeState)

  // The route must match exactly in order to use its primary component
  // otherwise defaulting to the current PrimaryComponent.
  const PrimaryComponent = routeState.match.isExact
    ? route.component
    : routeContext.PrimaryComponent

  const SecondaryComponent = route.components
    ? route.components.secondary
    : null

  const ModalComponent = route.components ? route.components.modal : null

  const fullRouteState = {
    ...route,
    ...routeState,
    ...legacyRouterState,
    // Some routes depend on legacy context, though unsure how they
    // were provided that data in the first place.
    school: window.school,
    user: window.user,
    // Adding newer context to routes:
    pwContext
  }

  function handleReplace(redirect) {
    // Transform the redirect into a url (pathname + search)
    // since redirect may be a string or obj.
    const redirectUrl = getUrlFromRoute(redirect)

    // Preserve query params when replacing with same base route.
    if (
      fullRouteState.location.pathname.startsWith(redirectUrl) ||
      redirectUrl.startsWith(fullRouteState.location.pathname)
    ) {
      const route = getRoute(redirectUrl, "", fullRouteState.location.search)
      setRedirect(route)
    } else {
      setRedirect(redirectUrl)
    }
  }

  // Handle legacy middleware methods (onEnter).
  useEffect(() => {
    // Calling route.onEnter every time the location changes.
    if (route.onEnter) {
      // HACK ALERT: Callback in legacy onEnter middleware would allow for only rendering
      // next component when route was ready (in cases where there were some async operations).
      const callback = async (maybeFn) => {
        // Potentially await the result of the callback arg.
        let maybePromise = null
        if (typeof maybeFn === "function") maybePromise = maybeFn()
        if (maybePromise instanceof Promise) await maybePromise
        setAwaitingCallback(false)
      }
      try {
        route.onEnter(fullRouteState, handleReplace, callback)
      } catch (error) {
        console.log("Routing error in onEnter: ", error)
      }
    }
  }, [routeState.location])

  // Handle legacy middleware methods (onLeave).
  useEffect(() => {
    if (route.onLeave) {
      return () => route.onLeave(fullRouteState)
    }
  }, [])

  // Clear redirect if location matches redirect. This indicates
  // that the current location has successfully been redirected to.
  useEffect(() => {
    const url = getUrlFromRoute(routeState.location)
    const redirectUrl = getUrlFromRoute(redirect)

    if (url === redirectUrl) {
      setRedirect(null)
    }
  }, [routeState.location])

  useEffect(() => {
    if (!awaitingCallback && !redirect) {
      updateAppRouteContext({
        // Prefer the current PrimaryComponent if route has sub components.
        PrimaryComponent: route.components
          ? // Referencing the routeContextRef.current's PrimaryComponent ensures we have
            // latest PrimaryComponent (see comment above regarding routeContextRef).
            routeContextRef.current.PrimaryComponent || PrimaryComponent
          : PrimaryComponent,
        SecondaryComponent,
        ModalComponent,
        routeState: fullRouteState
      })
    }
  }, [routeState.location, awaitingCallback, redirect])

  // Allowing Router to redirect
  if (redirect) {
    return <Redirect to={redirect} />
  }

  // all router component rendering handled by App
  return null
}

export default AppRoute
