export const RESET = "RESET"
export const ERROR = "ERROR"
export const SET_RENDERED_MEDIA = "SET_RENDERED_MEDIA"
export const SET_RENDERED_TAGS = "SET_RENDERED_TAGS"
export const CLEAR_SELECTED_BATCH = "CLEAR_SELECTED_BATCH"
export const DESELECT_MEDIA = "DESELECT_MEDIA"
export const START_UPLOADING = "START_UPLOADING"
export const STOP_UPLOADING = "STOP_UPLOADING"
export const SET_UPLOAD_COUNT = "SET_UPLOAD_COUNT"
export const PAGINATE_MEDIA = "PAGINATE_MEDIA"
export const START_EDITING = "START_EDITING"
export const STOP_EDITING = "STOP_EDITING"
export const SET_SELECTED_BATCH = "SET_SELECTED_BATCH"
export const SET_FILTER = "SET_FILTER"
export const SET_TYPE_FILTER = "SET_TYPE_FILTER"
export const SET_SEARCH = "SET_SEARCH"
export const START_LOADING = "START_LOADING"
export const STOP_LOADING = "STOP_LOADING"
export const SAVE_MEDIA_BATCH = "SAVE_MEDIA_BATCH"
export const DELETE_BATCH = "DELETE_BATCH"
export const DELETE_MEDIA = "DELETE_MEDIA"
export const START_SAVING_MEDIA = "START_SAVING_MEDIA"
